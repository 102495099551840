exports.components = {
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/homepage-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-air-container-loading-software-js": () => import("./../../../src/pages/air-container-loading-software.js" /* webpackChunkName: "component---src-pages-air-container-loading-software-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-breakbulk-vessel-loading-software-js": () => import("./../../../src/pages/breakbulk-vessel-loading-software.js" /* webpackChunkName: "component---src-pages-breakbulk-vessel-loading-software-js" */),
  "component---src-pages-compositions-js": () => import("./../../../src/pages/compositions.js" /* webpackChunkName: "component---src-pages-compositions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-equipment-library-index-js": () => import("./../../../src/pages/equipment-library/index.js" /* webpackChunkName: "component---src-pages-equipment-library-index-js" */),
  "component---src-pages-equipment-library-rest-api-api-2-holds-default-name-js": () => import("./../../../src/pages/equipment-library/{restApiApi2HoldsDefault.name}.js" /* webpackChunkName: "component---src-pages-equipment-library-rest-api-api-2-holds-default-name-js" */),
  "component---src-pages-equipment-library-rest-api-api-2-sets-default-name-js": () => import("./../../../src/pages/equipment-library/{restApiApi2SetsDefault.name}.js" /* webpackChunkName: "component---src-pages-equipment-library-rest-api-api-2-sets-default-name-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-pallet-calculator-software-js": () => import("./../../../src/pages/pallet-calculator-software.js" /* webpackChunkName: "component---src-pages-pallet-calculator-software-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-road-trailer-loading-software-js": () => import("./../../../src/pages/road-trailer-loading-software.js" /* webpackChunkName: "component---src-pages-road-trailer-loading-software-js" */),
  "component---src-pages-sea-container-loading-software-js": () => import("./../../../src/pages/sea-container-loading-software.js" /* webpackChunkName: "component---src-pages-sea-container-loading-software-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

