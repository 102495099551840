export default {
  colors: {
    primary: "#4678b2",
    background: "#FFFFFF",
    shape: `#F2F2FA`,
    title: `#3D3D4D`,
    text: `#6C6C80`,
    components: {
      blockquote: {
        background: `#feebc8`,
        text: `#2d3748`,
      },
    },
  },
}
